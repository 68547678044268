
// adapt FFMPEG_PATH with your environnement
// import * as path from "path";

module.exports = {
    // API_LOCATION: 'http://0.0.0.0:5000', // localhost
    API_LOCATION: 'https://server.pomelofactory.com',  // PROD https
    // API_LOCATION: 'https://pomelo.myhealthymap.com',  // DEV https
    PROD: true,  // only switch if we generate a version
    // FFMPEG_PATH: ffmpegPath, // mac
    GOOGLE_BUCKET_NAME: 'pomelo-transcription', // windows 
    URL_PPRO: __dirname,  
  };