import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
// @ts-ignore
import Tooltip from 'vue-directive-tooltip'
import 'vue-directive-tooltip/dist/vueDirectiveTooltip.css'
import VuePromiseBtn from 'vue-promise-btn'
import '@mdi/font/css/materialdesignicons.min.css'
import 'bootstrap/dist/css/bootstrap.min.css'
import VueI18n from 'vue-i18n'
import fr from './locales/fr'
import en from './locales/en'

Vue.config.productionTip = false
Vue.use(Tooltip)
Vue.use(VuePromiseBtn)
Vue.use(VueI18n)

// Create VueI18n instance with options
const i18n = new VueI18n({
  locale: 'fr-FR', // set locale
  messages: {
    'fr-FR': {
      message: fr
    },
    'en-US': {
      message: en
    }
  }, // set locale messages
});

new Vue({
  i18n,
  router,
  store,
  render: h => h(App)
}).$mount('#app')
