import axios from 'axios'
import {Pomelo} from '../../declarations';

const conf = require('../../../config/config.js');

const instance = axios.create({
    baseURL: conf.API_LOCATION
});

export async function initProject(Token:string) {
  try{
      const response = await instance.get('/project',{
        headers: {
          Authorization: 'Bearer '.concat(Token)
        }
      })
        .then((res)=>{
          return res.data.project;
        }
      ).catch(() => {
        // Sentry.captureException(err);
        return null;
      });
      return response;
  } catch(e) {
    // Sentry.captureException(e);
    console.error(e);
  } 
}

export async function sendApiObject(project_uid : string, obj : Pomelo.ApiObject, Token: string) {
  try{
    obj.project_uid = project_uid;
    const response = await instance.post('/rush',JSON.stringify(obj),{
      headers:{
        "Content-Type": 'application/json',
        "Authorization": 'Bearer '.concat(Token)
      }
    })
      .then((res)=>{
        return res.data.uid;
      }
    ).catch(() => {
      // Sentry.captureException(err);
      return null;
    });
    return response;
  } catch(e) {
    // Sentry.captureException(e);
    console.error(e);
    return null;
  } 
}


export async function sendScriptApi(script:string, uid: string, Token:string) {
  try{
    const message = {
      project_uid: uid, 
      script: script
    };
    const response = await instance.post('/script',JSON.stringify(message),{
      headers:{
        "Content-Type": 'application/json',
        "Authorization": 'Bearer '.concat(Token)
      }
    })
      .then((res)=>{
        return res.data;
      }
    ).catch(() => {
      // Sentry.captureException(err);
      return null;
    });
    return response;
  } catch(e) {
    // Sentry.captureException(e);
    console.error(e);
    return null;
  } 
}

export async function getQuotaApi(Token:string) {
  try{
      const response = await instance.get('/quota' ,{
        headers:{
          "Authorization": 'Bearer '.concat(Token)
        }
      })
      .then((res)=>{
        return res.data;
      }
    ).catch(() => {
      // Sentry.captureException(err);
      return null;
    });
    return response;
  } catch(e) {
    // Sentry.captureException(e);
    console.error(e);
    return null;
  } 
}


export async function addQuota(durationOfTrack:number, Token:string) {
  const message = {
    durationOfTrack: durationOfTrack, 
  };
  try{
    const response = await instance.put('/quota',JSON.stringify(message),{
      headers:{
        "Content-Type": 'application/json',
        "Authorization": 'Bearer '.concat(Token)
      }
    })
      .then((res)=>{
        return res.data;
      }
    ).catch(() => {
      // Sentry.captureException(err);
      return null;
    });
    return response;
  } catch(e) {
    // Sentry.captureException(e);
    console.error(e);
    return null;
  } 
}

export async function getQuotaSilenceApi(Token:string) {
  try{
      const response = await instance.get('/quotaSilent' ,{
        headers:{
          "Authorization": 'Bearer '.concat(Token)
        }
      })
      .then((res)=>{
        return res.data;
      }
    ).catch(() => {
      // Sentry.captureException(err);
      return null;
    });
    return response;
  } catch(e) {
    // Sentry.captureException(e);
    console.error(e);
    return null;
  } 
}


export async function addQuotaSilence(durationOfTrack:number, Token:string) {
  const message = {
    durationOfTrack: durationOfTrack, 
  };
  try{
    const response = await instance.put('/quotaSilent',JSON.stringify(message),{
      headers:{
        "Content-Type": 'application/json',
        "Authorization": 'Bearer '.concat(Token)
      }
    })
      .then((res)=>{
        return res.data;
      }
    ).catch(() => {
      // Sentry.captureException(err);
      return null;
    });
    return response;
  } catch(e) {
    // Sentry.captureException(e);
    console.error(e);
    return null;
  } 
}

export async function getScript(token: string, id: string) {
  const response = await instance.get(`/cluster/${id}`, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`
    }
  }).catch(() => {
    // Sentry.captureException(err);
    return null;
  });
  return response;
} 

export async function getFakeScript(token: string, id: string, index: number) {
  const response = await instance.get(`/fakecluster${index != 0 ? index : ''}/${id}`, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`
    }
  }).catch(() => {
    // Sentry.captureException(err);
    return null;
  });
  return response;
}

export async function generateScript(token: string, id: string) {
  const response = await instance.get(`/generate_script/${id}`, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`
    }
  }).catch(() => {
    // Sentry.captureException(err);
    return null;
  });
  return response?.data.script;
}

export async function sendInstructions(token:string, data:any) {
  const response = await instance.post('/generate_instruction_scripted', data, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`
    }
  }).catch(() => {
    // Sentry.captureException(err);
    return null;
  });
  return response;
}
