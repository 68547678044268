

































































































import Vue from "vue";
import { Component, Watch } from "vue-property-decorator";
import AuthsModule from "@/store/modules/auth";
import ProjectsModule from "@/store/modules/project";
import axios from 'axios';

const conf = require('../config/config');
const AuthInstance = AuthsModule;
const ProjectInstance = ProjectsModule;

@Component({})
export default class Home extends Vue {
  username = "";
  password = "";
  lang = "fr-FR";
  langs = ["en-US", "fr-FR"];
  errorAuth = false;

  @Watch("lang")
  onLangChanged(value: string, old: string) {
    this.$i18n.locale = value;
    window.localStorage.setItem("lang", value);
  }

  created() {
    this.lang = window.localStorage.getItem("lang") || "fr-FR";
  }

  async login() {
    if (this.username == "" || this.password == "") {
      alert(`${this.$t("message.auth.empty_inputs")}`);
    } else {
      AuthInstance.login({
        username: this.username,
        password: this.password,
      })
        .then(async (res: any) => {
          if (res) {
            let token = window.localStorage.getItem("Token");
            await axios.post(`${conf.API_LOCATION}/set_refresh`, null, {
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`
              },
            }); 
            this.$router.push("/");

            await ProjectInstance.getQuota();
          } else {
            this.errorAuth = true;
          }
        })
        .catch((err: any) => {
          this.errorAuth = true;
        });
    }
  }

  openFAQ() {
    let urlFr =
      "https://www.notion.so/pomelofactory/1ec0de19990a49bb8e87e9f042a10447?v=190f48c245ca470186b507f755b93caf";
    let urlEn =
      "https://www.notion.so/pomelofactory/ff593a36f11e4b5dae2d2435d136abd5?v=262cab39381148ddb32f2f948d1b1438";
    if (this.$i18n.locale == "fr-FR") {
      open(urlFr, "_blank");
    } else {
      open(urlEn, "_blank");
    }
  }
}
