












































































import Vue from "vue";
import logoutButton from "@/views/components/LogOutComponent.vue";
import { Component } from "vue-property-decorator";
import AuthsModule from "@/store/modules/auth";
import FeedbackModule from "@/store/modules/feedback";
import axios from "axios";
import AppModule from "@/store/modules/app";
import ProjectsModule from "@/store/modules/project";
import moment from "moment";
import Header from "./components/Header.vue";
import Footer from "./components/Footer.vue";
var conf = require("../config/config.js");


var momentDurationFormatSetup = require("moment-duration-format");
momentDurationFormatSetup(moment);

@Component({
  components: {
    logoutButton,
    Header,
    Footer,
  },
})
export default class Profile extends Vue {
  AuthInstance = AuthsModule;
  ProjectInstance = ProjectsModule;
  feedbackInstance = FeedbackModule;
  app = AppModule;
  username!: string;
  subscription!: string;
  subscription_uid!: number;
  email!: string;
  password: string = "********";
  feedbackSuccess: boolean = false;
  saved: boolean = false;
  
  error: boolean = false;
  errorUsername: boolean = false;
  errorPassword: boolean = false;
  received: boolean = false;

  created() {
    this.app.setCurrentPage(this.$router.currentRoute.path);
    let token = window.localStorage.getItem("Token");
    this.feedbackInstance.setPageId(6);
    axios
      .get(`${conf.API_LOCATION}/user`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        let data = res.data.data;
        this.username = data.username;
        this.email = data.email;
        this.subscription_uid = data.subscription_uid;
        this.subscription = data.subscription;
      })
      .catch((err) => {
        // Sentry.captureException(err);
      })
      .finally(() => {
        this.received = true;
      });
  }

  currentQuota() {
    if (!this.ProjectInstance.quota.isQuotaReached) {
      return `<span \
            style="color: green" \
            >${this.formatDate(
              this.ProjectInstance.quota.current_quota
            )}</span>`;
    } else if (this.ProjectInstance.quota.isQuotaReached) {
      return `<span \
            style="color: red" \
            >${this.formatDate(
              this.ProjectInstance.quota.current_quota
            )}</span> `;
    } else {
      return `<span style="color: yellow">${this.formatDate(
        this.ProjectInstance.quota.current_quota
      )}</span>`;
    }
  }

  formatDate(value: number) {
    if (value) {
      var duration: any = moment.duration(value, "seconds");
      var formatted = duration.format("HH:mm:ss");
      formatted = formatted.split(":");
      if (formatted.length == 3) {
        formatted = `${formatted[0]}h ${formatted[1]}min ${formatted[2]}s`;
      } else if (formatted.length == 2) {
        formatted = `00h ${formatted[0]}min ${formatted[1]}s`;
      } else {
        formatted = `00h 00min ${formatted[0]}s`;
      }
      return formatted;
      // return moment(String(value)).format('hh:mm:ss');
    } else {
      return value + " Sec";
    }
  }

  updateProfile() {
    if (this.username.length < 4) {
      this.errorUsername = true;
      return 0;
    }
    if (this.password.length < 8 && this.password != "********") {
      this.errorPassword = true;
      return 0;
    }
    let token = window.localStorage.getItem("Token");
    axios
      .put(
        `${conf.API_LOCATION}/user`,
        {
          username: this.username,
          email: this.email,
          subscription_uid: this.subscription_uid,
          password: this.password == "********" ? "" : this.password,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        this.saved = true;
        this.error = false;
      })
      .catch((err) => {
        // Sentry.captureException(err);
        this.error = true;
      });
  }
}
