import { VuexModule, Module, Action, Mutation,getModule} from 'vuex-module-decorators';
import store from '@/store';
import { Feedback } from '../models';
import { feedback } from './api';

@Module({
  namespaced: true,
  dynamic: true,
  name: 'feedback',
  store,
  preserveState: localStorage.getItem('vuex') !== null,
})

class FeedbackModule extends VuexModule {
  feed?: Feedback | null = null
  pageId = 1;

  get myFeedback(): Feedback | null | undefined {
    return this.feed;
  }

  get myPageId(): number {
    return this.pageId;
  }

  @Mutation
  setFeedback(feed: Feedback): void {
    this.feed = feed;
  }

  @Mutation
  setPageId(value: number): void {
    this.pageId = value;
  }

  @Action({commit: 'setFeedback', rawError: true})
  async getFeed(): Promise<any>{
    const token = window.localStorage.getItem('Token');
    const response = await feedback.getFeedback(token);
    if(response) {
      return response;
    }
    return false;
  }

  @Action({commit: 'setFeedback', rawError: true})
  async putFeed(feed: Feedback): Promise<any> {
    const token = window.localStorage.getItem('Token');
    const response = await feedback.putFeedback(token, feed);
    if(response?.data?.success) {
      return response;
    }
    return false;
  }
    
}

export default getModule(FeedbackModule);