




















































































import { Component } from "vue-property-decorator";
import Vue from "vue";
import axios from "axios";
// import * as Sentry from "@sentry/vue";
const conf = require("../config/config");

@Component({})
export default class ForgotPassword extends Vue {
  email: string = "";
  code: string = "";
  password: string = "";
  emailSent: boolean = false;
  sendEmail: boolean = true;
  emailError: boolean = false;
  updateError: boolean = false;
  updated: boolean = false;
  emailErrorMsg: string = "";
  errorMsg: string = "";

  async send() {
    await axios
      .post(`${conf.API_LOCATION}/forgot`, {
        email: this.email,
      })
      .then((res) => {
        this.sendEmail = false;
        this.emailSent = true;
        this.emailError = false;
      })
      .catch((err: any) => {
        // Sentry.captureException(err);
        let data = err.response.data;
        this.emailError = true;
        if(data.error.code == 102) {
          this.emailErrorMsg = this.$t('message.password_recovery.error_email').toString();
        }else {
          this.emailErrorMsg = data.error.message;
        }
      })
      .finally(() => {
        document.querySelector(".panel")?.scrollBy(0, 1000);
      });
  }

  async update() {
    await axios
      .post(`${conf.API_LOCATION}/reset`, {
        email: this.email,
        token: this.code,
        password: this.password,
      })
      .then(async (res) => {
        this.updated = true;
        this.updateError = false;
        alert(`${this.$t("message.password_recovery.success")}`);
        this.$router.push("/login");
      })
      .catch((err: any) => {
        // Sentry.captureException(err);
        let data = err.response.data;
        this.updateError = true;
        if(data.error.code == 102) {
          this.errorMsg = this.$t('message.password_recovery.error_email').toString();
        } else if(data.error.code == 115) {
          this.errorMsg = this.$t('message.password_recovery.incorrect_token').toString();
        }else {
          this.errorMsg = data.error.message;
        }
      })
      .finally(() => {
        document.querySelector(".panel")?.scrollBy(0, 1000);
      });
  }

  openFAQ() {
    let urlFr =
      "https://www.notion.so/pomelofactory/1ec0de19990a49bb8e87e9f042a10447?v=190f48c245ca470186b507f755b93caf";
    let urlEn =
      "https://www.notion.so/pomelofactory/ff593a36f11e4b5dae2d2435d136abd5?v=262cab39381148ddb32f2f948d1b1438";
    if (this.$i18n.locale == "fr-FR") {
      open(urlFr, "_blank");
    } else {
      open(urlEn, "_blank");
    }
  }
}
