















































































import Vue from "vue";
import { Component } from "vue-property-decorator";
import logoutButton from "@/views/components/LogOutComponent.vue";
import FeedbackModule from "@/store/modules/feedback";
import AuthsModule from "@/store/modules/auth";
import AppModule from "@/store/modules/app";
import Header from "./components/Header.vue";

@Component({
  components: {
    logoutButton,
    Header,
  },
})
export default class Feedback extends Vue {
  success: boolean = false;
  error: boolean = false;
  empty: boolean = false;
  subject: string = '';
  message: string = "";
  feedInstance = FeedbackModule;
  AuthInstance = AuthsModule;
  appInstance = AppModule;
  // $mixpanel: any;
  withRating: boolean = false;
  askRating: boolean = false;

  get optionsComputed() {
    let options = eval(this.$t('message.feedback.options').toString());
    return options;
  }
  
  created() {
    if(this.$route.params.hasOwnProperty("rating")){
      this.withRating = true;
    } else{
      this.withRating = false;
    }
    console.log(this.$i18n)
    this.subject = this.$i18n.locale == 'fr-FR' ? 'Autre' : 'Other';
    this.appInstance.setCurrentPage(this.$router.currentRoute.path);
  }

  setRating(value: string) {
    this.$route.params["rating"] = value.toString();
    let emojis = document.getElementsByClassName("emoji");
    for(let i=0; i < emojis.length; i++) {
      let child = emojis[i].children[0];
      if(child.id == "emoji"+value.toString()){
        child.classList.toggle("chosen");  
      } else{
        child.classList.remove("chosen");
      }
    }
    console.log(this.$route.params.rating);
  }
  async send() {
    let page = this.$route.query.page;
    this.empty = false;
    if (this.subject && this.message ) {
      let data: any = {
        message: this.message,
        subject: this.subject,
        page_uid: page,
      };
      if (this.$route.params.rating) {
        data.rating = parseInt(this.$route.params.rating);
        this.askRating = false;
      }else{
        this.askRating = true;
        return;
      }
      await this.feedInstance
        .putFeed(data)
        .then((res: any) => {
          if(res) {
            this.success = true;
            this.error = false;
            // this.$mixpanel.track("Feedback Success", {
            //   distinct_id: this.AuthInstance.user.username,
            // });
          }else {
            this.error = true;
            this.success = false;
          }
        })
        .finally(() => {
          document.querySelector(".panel")?.scrollBy(0, 1000);
        });
    } else {
      this.empty = true;
      document.querySelector(".panel")?.scrollBy(0, 1000);
    }
  }
}
