
















































































import Vue from "vue";
import { Component, Watch } from "vue-property-decorator";
import Navbar from "./components/Navbar.vue";
import axios from "axios";
import ProjectsModule from "@/store/modules/project";
import AuthsModule from '@/store/modules/auth';
import AppModule from "@/store/modules/app";
const config = require("../config/config");

const projectInstance = ProjectsModule;
const AuthInstance = AuthsModule;
const app = AppModule;
@Component({
  components: {
    Navbar,
  },
})
export default class Home extends Vue {
  projects: any = [];
  pages: any = 0;
  currentPage = 1;
  keyword = "";
  selectedProject = "";
  showAll: boolean = false;
  bins: any;

  async created() {
    await this.fetchData();
  }

  @Watch("showAll")
  onProperyChange(value: string) {
    this.fetchData();
  }

  search(keyword: string) {
    this.keyword = keyword;
    this.currentPage = 1;
    this.fetchData();
  }

  async logout() {
    let confirmation = confirm(`${this.$t("message.auth.disconnect")}`);
    if (confirmation) {
      AuthInstance.logout();
      localStorage.removeItem("Token");
      localStorage.removeItem("Token-refresh");
      localStorage.removeItem("expiration_date");
      this.$router.push("/login").catch((e) => console.log(e));
    }
  }

  async fetchData() {
    const token = localStorage.getItem("Token");
    let data: any = {
      page: this.currentPage,
    };
    if (this.keyword) {
      data.keyword = this.keyword;
    }
    data.all = !this.showAll;
    let response = await axios.post(`${config.API_LOCATION}/projects`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    if (response.data.success) {
      this.projects = response.data.data;
      let count = response.data.count;
      this.pages = Math.ceil(count / 20);
      this.$forceUpdate();
    }
  }

  parseDate(time: any) {
    let date = new Date(time.split(".")[0]);
    let month = `${date.getMonth() + 1}`;
    let day = `${date.getDate()}`;
    let hours = `${date.getHours()}`;
    let minutes = `${date.getMinutes()}`;
    month = parseInt(month) < 10 ? "0" + month : month;
    day = parseInt(day) < 10 ? "0" + day : day;
    hours = parseInt(hours) < 10 ? "0" + hours : hours;
    minutes = parseInt(minutes) < 10 ? "0" + minutes : minutes;
    return `${date.getFullYear()}/${month}/${day} ${hours}h${minutes}`;
  }

  setProject(uid: string, event: any) {
    this.selectedProject = uid;
    let target: HTMLElement = event.target;
    let selectedRow = document.querySelector("table tbody tr.active");
    selectedRow?.classList.remove("active");
    let row = target.closest("tr");
    row?.classList.add("active");
  }

  async toPage(page: number) {
    this.currentPage = page;
    await this.fetchData();
  }
  openProject() {
    projectInstance.setProject({
      project_uid: this.selectedProject,
    });
   
    let element = this.projects.find(
      (project: any) => project.uid == this.selectedProject
    );
    projectInstance.setMulticam(element.is_multicam);
    localStorage.setItem("bin", element.bin_name);
    projectInstance.setProjectName(element?.project_name);
    projectInstance.setSelectedBinName(element?.bin_name);
    this.$router.push("/without-script");
  }

  openFAQ() {
    let urlFr =
      "https://www.notion.so/pomelofactory/1ec0de19990a49bb8e87e9f042a10447?v=190f48c245ca470186b507f755b93caf";
    let urlEn =
      "https://www.notion.so/pomelofactory/ff593a36f11e4b5dae2d2435d136abd5?v=262cab39381148ddb32f2f948d1b1438";
    if (this.$i18n.locale == "fr-FR") {
      open(urlFr, "_blank");
    } else {
      open(urlEn, "_blank");
    }
  }

  deleteProject() {
    let token = localStorage.getItem("Token");
    axios
      .delete(`${config.API_LOCATION}/removeProject/${this.selectedProject}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.data.success) {
          let index = this.projects.findIndex(
            (project: any) => project.uid == this.selectedProject
          );
          if (index > -1) {
            this.projects.splice(index, 1);
            this.$forceUpdate();
          }
        }
      });
  }
}
