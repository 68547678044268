import axios from "axios";
import { Feedback } from '../../models';
const conf = require('../../../config/config.js');

export const instance = axios.create({
    baseURL: conf.API_LOCATION
});

export async function getFeedback(token: string | null) {
  const response = await instance.get('/feedback', {
    headers: {
      "Content-Type": 'application/json',
      "Authorization": `Bearer ${token}`
    }
  }).catch((err)=> {
    // Sentry.captureException(err);
    return null;
  });
  return response;
}

export async function putFeedback(token: string|null, feed: Feedback) {
  const response = await instance.post('/feedback', feed, {
    headers: {
      "Content-Type": 'application/json',
      "Authorization": `Bearer ${token}`
    }
  }).catch((err)=> {
    // Sentry.captureException(err);
    return null;
  });
  return response;
}
