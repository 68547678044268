














import Vue from 'vue';
import {Component, Prop} from 'vue-property-decorator';
@Component({})
export default class EditWord extends Vue {
  @Prop({ default: null })
  word: any;
  @Prop({ type: Function, default: () => {} })
  saveInput: any;
  @Prop({ type: Function, default: () => {} })
  save: any;
  @Prop({ type: Number, default: () => {} })
  index: any;
  @Prop({ type: Number, default: () => {} })
  wIndex: any;
}
