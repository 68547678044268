export namespace Pomelo{
    export interface bin {
        name:string;
        value:number[];
    }

    export interface FollowerRush {
        id: string,
        etat: boolean
    }

    export class AudioObj {
        debut = 0.0;
        fin = 0.0;
    }

    export class ArrVideo {
        pathVideo = "";
        cutting: Pomelo.AudioObj[] = [];
    }


    export class Video{
        readonly __video__ = true; 
        length = 0;
        offset = 0;
        id = "";
    }

    export class Audio{
        readonly __audio__ = true; 
        length = 0;
        offset = 0;
        media_path : string | undefined = undefined;
        id = ""; // temporary identifier used as a filename on google cloud storage.
    }

    export class Rush{
        readonly __rush__ = true;
        media_index=0;
        path="";
        videos: Video[] = [];
        audios: Audio[] = [];
        uid="";
    }

    export class RushWithDecoup{
        readonly __rush__ = true;
        videos: Video[] = [];
        audios: Audio[] = [];
        audioObj: AudioObj[] = [];
    }

    export class ApiObject{
        project_uid = "";
        media_name = "";
    }

    export class RemoteRushItem extends ApiObject{
        length = 0;
        offset = 0;
        rush_uid = "";
    }

    export class RemoteVideo extends RemoteRushItem{
        readonly __video__ = true; 
    }

    export class RemoteAudio extends RemoteRushItem{
        readonly __audio__ = true;
        audio: any | undefined = undefined;
        mean_volume = 0;
    }

    export class RemoteRush extends ApiObject{ // class for sending rushes
        readonly __rush__ = true;
        project_uid = "";
        media_index = 0;
        
        // audios: string[] = []; // everything replaced by their uid
        // videos: string[] = [];
    }

    export class TranscriptionSegment{
        length = 0;
        probability = 0;
        text = "";
        speaker = 0;
        hesitation = false;
    }

    export type transcription = TranscriptionSegment[][];


    export type rush_item_type = "__audio__" | "__video__";
    /**
    * @brief an instruction explaining how to insert a clip
    */
    export type instruction = [string, number, number, rush_item_type, any] ; // uid * start * len * type
    export type instructions_linked_group = instruction[]; // a group of instruction reffering to media that 
                                                            // should be linked together
    export type instructions = instructions_linked_group[][] //I like brackets
        // list of media that should be grouped together in the organisation


}
