const messages = {
  app: {
    home: "Accueil",
    projects: "Projets",
    logout: "Déconnecter",
    help: "Aide",
  },
  auth: {
    login: "Se connecter",
    username: "Nom d'utilisateur",
    title: "Connectez vous et laissez la magie opérer.",
    password: "Mot de passe",
    invalid: "Nom d'utilisateur ou mot de passe incorrect",
    forgot: "J'ai oublié mon mot de passe",
    first_time: "Première fois sur Derush ?",
    disconnect: "Voulez-vous vraiment vous déconnecter ?",
    empty_inputs: "Le nom d\\'utilisateur et le mot de passe ne doivent pas être vides!",
  },
  interview_editor: {
    title: "Editeur d'interview",
    bin: "Choisir le chutier",
    hints: "Ajouter des mots-clés",
    quality: "Haute qualité",
    multicam: "Séquence multi-source",
    transcribe: "Transcrire",
    organize: "Organiser le montage",
    uploading: "Chargement en cours... Détendez-vous",
    transcribing: "Transcription en cours... On s'occupe de tout",
    analyzing: "Analyse des mots en cours... Quelle éloquence",
    go_home_warning: "Etes-vous sûr de vouloir retourner à la page de transcription ? Vous perdrez cette version.",
    empty_bin: "Le chutier choisi est vide ou ne contient pas de fichier valide.",
    cancel_transcription: "Annuler",
    cancel_warning: "La transcription est en cours. Etes-vous sûr de vouloir l\\'arrêter ?",
    tooltip_bin: "Le chutier est le dossier Premiere Pro qui contient les médias que vous voulez transcrire.",
    tooltip_hints: "Les mots-clés sont des mots (séparés par des virgules) qui sont mentionnés et importants dans votre interview. Ecrivez les noms propres ou les termes techniques évoqués.",
    tooltip_quality: "Des médias de haute qualité prendront plus de temps à charger mais augmenteront la qualité de la transcription.",
    tooltip_multicam: "Synchronisez au préalable vos sources vidéo/audio dans une séquence Premiere Pro puis sélectionnez le chutier qui contient cette séquence.",
    tooltip_save_project: "Enregistrer le projet",
    error_quota: "Votre quota de transcription est insuffisant pour transcrire ce chutier.",
    undo: "Annuler",
    reset: "Réinitialiser",
    delete: "Supprimer",
    reset_warning: "Voulez-vous vraiment réinitialiser votre travail?",
    download: "Télécharger le transcript",
    generated: "Transcription générée ! Vous pouvez la trouver dans le panneau Projet.",
    quota_reached: "Votre temps de transcription maximum a été atteint",
    error_transcription: "Erreur de transcription, veuillez réessayer",
    error_ffmpeg: "Erreur dans ffmpeg",
    error_media: "Erreur media est hors ligne",
    error_multicam_bin: "Le chutier choisi doit contenir une seule séquence valide.",
    ratio: "la sequence finale inclue {0}% de la durée de vos rushs",
    options: {
      show_sequence: "Montrer dans la séquence",
      split_sentence: "Dissocier"
    },
    info: {
      text_1: "Faites un clic droit sur un mot pour accèder à ces options :",
      text_2: "Une fois que vous êtes satisfait de votre transcript, appuyez sur\
      <span class='organize-btn mx-1'>GO</span> pour créer une nouvelle séquence\
      prenant en compte vos modifications.",
      text_3: "Vous pouvez télécharger votre nouveau script avec les nouveaux timecodes en cliquant sur\
      <span class='download-btn mx-1'>\
        <span class='brutalism-icon mdi mdi-download' style='font-size: 12px; width: 12px;'></span>\
      </span>"
    }
  },
  feedback: {
    title: "Retours",
    intro: "Votre retour concerne...",
    comment: "Dites-nous tout !",
    submit: "Envoyer",
    thanks: "Merci pour votre retour ! On va s'assurer que tout fonctionne correctement.",
    options: "[\"Editeur d'interview\", \"Connexion\", \"Suppression des silences\",\"Paramètres\", \"Chargement\", \"Confidentialité des données\", \"Autre\"]",
    error: "Une erreur est apparu lors de l'envoi de votre retour. <br/> Essayez de nouveau",
    empty: "",
    rating_missing: "SVP cliquer un emoji pour choisir une note"
  },
  recommendation: {
    title: "Recommendation",
    referral: "Quelle est la probabilité que vous recommandiez Derush à vos amis et collègues ?",
    rating: "Ça vous plaît ?",
    submit: "Envoyer",
    thanks: "Merci pour votre retour ! On va s'assurer que tout fonctionne correctement.",
    error: "Une erreur est apparu lors de l'envoi de votre recommendation. <br/> Essayez de nouveau",
    error_empty: "Sélectionnez une note et un message",
    message_label: "Dites-nous ce que vous aimez et ce que vous n'aimez pas pour que nous puissions nous améliorer",
  },
  account: {
    title: "Compte",
    username: "Votre nom d'utilisateur",
    password: "Votre mot de passe",
    email: "Votre email",
    subscription: "Votre abonnement",
    save: "Sauvegarder",
    updated: "Vos changements ont bien été pris en compte",
    quota: "Vous avez utilisé {0} de vos {1} de transcription disponible.",
    upgrade: "Mettre à niveau"
  },
  password_recovery: {
    title: "Récupération de mot de passe",
    email: "Votre email",
    send: "Envoyer",
    error_email: "L'email entré ne correspond pas à un compte d'utilisateur.",
    code: "Code de vérification",
    new_password: "Votre nouveau mot de passe",
    update: "Sauvegarder",
    incorrect_token: "Code de vérification invalide",
    success: "Votre mot de passe a bien été changé !"
  },
  transcript: {
    generated: "Transcription générée! Vous pouvez le trouver dans le panneau Média",
    error_import: "Erreur dans l\\'importation du fichier",
  },
  navbar: {
    moreOptions: "Plus d'options",
    afterPadding: "Marge entrée (s)",
    beforePadding: "Marge sortie (s)",
    splitGap: "Seuil de division des blocs",
    updateExisting: "Mise à jour séquence existante",
  },
  projects: {
    show_all: 'Afficher tous les projets',
  }
};

export default messages;