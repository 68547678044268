import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import Home from '../views/Home.vue'
import Auth from '../views/Auth.vue'
import WithoutScript from '../views/WithoutScript.vue'
import BugReport from '../views/BugReport.vue'
import AuthsModule from '../store/modules/auth'
import Feedback from '../views/Feedback.vue';
import Profile from '../views/Profile.vue';
import ForgotPassword from '../views/ForgotPassword.vue';

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'home',
    component: Home,
    meta: {requiresAuth: true}
  },
  {
    path: '/login',
    name: 'login',
    component: Auth
  },
  {
    path: '/without-script',
    name: 'without-script',
    component: WithoutScript,
    meta: {requiresAuth: true},
  },
  {
    path: '/bug-report',
    name: 'bug-report',
    component: BugReport,
    meta: {requiresAuth: true},
  },
  {
    path: "/profile",
    name: "profile",
    component: Profile,
    meta: {requiresAuth: true}
  },
  {
    path: "/forgot-password",
    name: "forgot-password",
    component: ForgotPassword,
    meta: {requiresAuth: false}
  },
  {
    path: "/feedback/:rating?",
    name: "feedback",
    component: Feedback,
    meta: {requiresAuth: true},
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

const instance = AuthsModule;

router.beforeEach(async (to, from, next) => {
  var expirationDate = "0";
  if(localStorage.getItem("expiration_date") != "") {
    expirationDate = localStorage.getItem("expiration_date") ?? "0";
  }
  var expiration = Date.parse(expirationDate);
  var diff = expiration - new Date().getTime();
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if(window.localStorage.getItem("Token") != (""||null) && await instance.testToken() && diff > 1) {
      next();
    }else {
      next('/login');
    }
    
  } else {
    next();
  }
  
});

export default router
