










































































import { Feedback } from "../../store/models";
import FeedbackModule from "@/store/modules/feedback";
import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";

const feedbackInstance = FeedbackModule;

@Component({})
export default class Footer extends Vue {
  @Prop({ default: false })
  transcribing: boolean = false;
  @Prop({ type: Function, default: () => {} })
  cancel!: any;

  feedbackSuccess: boolean = false;

  async sendFeedback(rating: number) {
    let page = 1;
    switch (this.$router.currentRoute.name) {
      case "profile":
        page = 6;
        break;
      case "without-script":
        page = 5;
        break;
      case "home":
        page = 4;
        break;
    }
    let confirm = true;
    // if (this.transcribing) {
    //   confirm = await evalScript(
    //     `confirm('${this.$t("message.interview_editor.cancel_warning")}', false, 'warning')`
    //   );
    // }
    if (confirm) {
      this.cancel();
      if (rating != 5) {
        this.$router.push(`/feedback/${rating}?page=${page}`);
      } else {
        let feed: any = {
          rating: rating,
          page_uid: page,
        };
        feedbackInstance.putFeed(feed).then((res: any) => {
          if (res) {
            this.feedbackSuccess = true;
          }
        });
      }
    }
  }

  openFAQ() {
    let urlFr =
      "https://www.notion.so/pomelofactory/1ec0de19990a49bb8e87e9f042a10447?v=190f48c245ca470186b507f755b93caf";
    let urlEn =
      "https://www.notion.so/pomelofactory/ff593a36f11e4b5dae2d2435d136abd5?v=262cab39381148ddb32f2f948d1b1438";
    if (this.$i18n.locale == "fr-FR") {
      open(urlFr, "_blank");
    } else {
      open(urlEn, "_blank");
    }
  }
}
