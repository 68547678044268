








































































































































































































import Vue from "vue";
import { Component } from "vue-property-decorator";
import logoutButton from "@/views/components/LogOutComponent.vue";
import FeedbackModule from "@/store/modules/feedback";
import ProjectsModule from "@/store/modules/project";
import AuthsModule from "@/store/modules/auth";
import AppModule from "@/store/modules/app";
import Header from "./components/Header.vue";
import Footer from "./components/Footer.vue";
import Navbar from "./components/Navbar.vue";
import EditWord from "./components/without-script/EditWord.vue";
const { exec } = require("child_process");
import axios from "axios";
var fs = require("fs");

var conf = require("../config/config.js");
const projectInstance = ProjectsModule;
const feedbackInstance = FeedbackModule;
const AuthInstance = AuthsModule;
const app = AppModule;
@Component({
  components: {
    logoutButton,
    Header,
    Footer,
    Navbar,
    EditWord,
  },
})
export default class ProcessScript extends Vue {
  alertSuccess: boolean = false;
  scriptProcessed: boolean = false;
  instructions: any;
  sentences: any = [];
  // $mixpanel: any;
  data: any = [];
  originalData: any = [];
  script: string = "";
  scriptBlocks: Array<string> = [];
  searchIds: Array<string> = [];
  chosenWord: string = "";
  element: any;
  withScript: boolean = false;
  containerKey: number = 1;
  error: boolean = false;
  afterElement!: HTMLElement;
  target!: HTMLElement;
  conf: any = conf;
  showInfos: boolean = false;
  showColor: boolean = false;
  selectedSentence: number = -1;
  timeRatio: string = "";
  quit: boolean = false;
  saveModal: boolean = false;
  project_name: string = "";
  bins: any;
  keyword: string = '';
  bin: string = '';
  version: number = 1;
  downloadUrl: string = '';
  historyPosition: number = -1;
  
  colorsLabel = [
  {label : 0 , name : "Violet" , codeCss : "#A690E0" },
  {label : 1 , name : "Iris" , codeCss : "#729ACC" },
  {label : 2 , name : "Caribbean" , codeCss : "#29D698" },
  {label : 3 , name : "Lavender" , codeCss : "#E384E3" },
  {label : 4 , name : "Cerulean" , codeCss : "#2FBFDE" },
  {label : 5 , name : "Forest" , codeCss : "#51B858" },
  {label : 6 , name : "Rose" , codeCss : "#F76FA4" },
  {label : 7 , name : "Mango" , codeCss : "#EDA63B" },
  {label : 8 , name : "Purple" , codeCss : "#970097" },
  {label : 9 , name : "Blue" , codeCss : "#3C3CFF" },
  {label : 10 , name : "Teal" , codeCss : "#008080" },
  {label : 11 , name : "Magenta" , codeCss : "#E732E7" },
  {label : 12 , name : "Tan" , codeCss : "#CEC195" },
  {label : 13 , name : "Green" , codeCss : "#1D7021" },
  {label : 14 , name : "Brown" , codeCss : "#8B4513" },
  {label : 15 , name : "Yellow" , codeCss : "#E2E264" },
];

  async created() {
    feedbackInstance.setPageId(5);
    this.project_name = projectInstance.project_name;
    app.setCurrentPage(this.$router.currentRoute.path);
    this.downloadUrl = `${conf.API_LOCATION}/download-web-txt-time-script/${projectInstance.uid}`;
    this.bin = projectInstance.selected_bin_name;
    // if(!projectInstance.withoutScriptData.length) {
      
    let token = localStorage.getItem("Token");
    let response = await axios.get(
      `${conf.API_LOCATION}/project/${projectInstance.uid}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    let project = response.data.project;
    let data: any;
    if(projectInstance.isMulticam || project.is_multicam ) {
      const token = localStorage.getItem("Token");
      let response = await axios.put(`${conf.API_LOCATION}/generate_script/${projectInstance.uid}`, {
        filtreRange: projectInstance.multicamData
      }, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      data = response.data.script;
    }else {
      data = await projectInstance.generateScripts();
    }

    this.data = data;
    this.originalData = JSON.parse(JSON.stringify(data));
    let arr: any = JSON.parse(JSON.stringify(data));
    projectInstance.setWithoutScriptHistory([]);
    projectInstance.setWithoutScriptData(arr);
    // }
    data.map((group: Array<string>) => {
      let words: Array<string> = [];
      let blocks: string = "";
      group.map((word: string) => {
        words.push(word);
      });
      group.map((word: any) => {
        blocks += word.text + " ";
      });
      this.scriptBlocks.push(blocks);
      blocks = "";
    });
    this.containerKey += 1;
    let $this = this;
    document.oncontextmenu = function (e) {
      e.preventDefault()
      let selected = document.querySelector('.highlighted');
      selected?.classList.remove('highlighted');
      let el = e.target as HTMLElement;
      if (el?.classList.contains("word-container")) {
        $this.element = el;
      } else if (el.classList.contains("word")) {
        $this.element = el.parentNode;
      } else {
        $this.element = null;
      }
      $this.element?.classList.add('highlighted');
      const menu: HTMLElement | null = document.querySelector('#context-menu');
      console.log(e)
      menu?.style.setProperty('top', `${e.clientY}px`);
      menu?.style.setProperty('left', `${e.clientX}px`);
      console.log(menu?.style)
      menu?.classList.add('show');
      menu?.focus()
    };
  }

  toggleMoreOptions (){
    projectInstance.setWithoutScriptshowMoreOptions(false);
  }

  hide() {
    document.querySelector('#context-menu')?.classList.remove('show')
  }


  toggleShowColor (indexSentence : number){
    this.selectedSentence = indexSentence;
    this.showColor = true;
  }

  chooseColor(value:any){
    let newData = this.data;
    newData[this.selectedSentence][0].color = value;
    this.data = newData;
    projectInstance.setWithoutScriptData(
      JSON.parse(JSON.stringify(this.data))
    );
    this.showColor = false;
    this.selectedSentence = - 1;
  }

  showModal() {
    this.showInfos = true;
  }

  confirmQuit() {
    this.quit = true;
  }

  showSaveModal() {
    this.saveModal = true;
  }

  async saveProject() {
    await projectInstance.sendScripts(projectInstance.withoutScriptData);
    let token = localStorage.getItem("Token");
    let bin_name = localStorage.getItem("bin");
    axios
      .put(
        `${conf.API_LOCATION}/project/${projectInstance.uid}`,
        {
          project_name: this.project_name,
          bin_name,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        projectInstance.setProjectName(this.project_name);
        this.saveModal = false;
      });
  }

  async goBack(save: boolean = true) {
    if (save) {
      await this.sendScripts();
      let token = localStorage.getItem("Token");
      let bin_name = localStorage.getItem("bin");
      await axios.put(
        `${conf.API_LOCATION}/project/${projectInstance.uid}`,
        {
          project_name: this.project_name,
          bin_name,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
    }
    app.setCurrentPage("");
    projectInstance.clearAll();
    window.localStorage.setItem("transcribed", "0");
    this.$router.push("/");
    return 0;
  }

  //returns a txt file with the start_time of each block
   async downloadTxtTimeScript(): Promise<void> {
    let form: HTMLFormElement | null = document.querySelector('#download');
    form?.submit();
    this.version += 1;
  }

  async finishDownload(tmpPath: string){
    const project_uid = projectInstance.uid;
    let bin: any = window.localStorage.getItem("bin");
    const url = `${conf.API_LOCATION}/download-txt-time-script/${project_uid}`;
    const body = JSON.stringify({
      bin: bin,
      time_between_words: parseFloat(projectInstance.withoutScriptTimeBetweenWords),
      paddingBefore: parseFloat(projectInstance.withoutScriptPaddingBefore),
      paddingAfter: parseFloat(projectInstance.withoutScriptPaddingAfter),
      version: projectInstance.transcriptDownloadCount,
    });
    if(!fs.existsSync(tmpPath)){
      fs.mkdirSync(tmpPath); 
    }
    let now = new Date();
    let time =
      "" +
      now.getFullYear() +
      ("0" + (now.getMonth() + 1)).slice(-2) +
      ("0" + now.getDate()).slice(-2) +
      "-" +
      ("0" + now.getHours()).slice(-2) +
      "h" +
      ("0" + now.getMinutes()).slice(-2);
    const pathTmp = `${tmpPath}/${bin} (Derush Transcript) v${projectInstance.transcriptDownloadCount} (${time}).txt`;
    let script = projectInstance.withoutScriptData;
    await projectInstance.sendScripts(script);
    alert(`${this.$t("message.transcript.generated")}`);
  }

  updateHistory(action: any) {
    if (
      projectInstance.withoutScriptHistory.length - 1 >
      this.historyPosition
    ) {
      projectInstance.withoutScriptHistory.splice(
        this.historyPosition + 1
      );
    }
    projectInstance.withoutScriptHistory.push(action);
    this.historyPosition += 1;
  }

  async reset() {
    let res = false;
    res = confirm('${this.$t("message.interview_editor.reset_warning")}');
    if (res) {
      this.data = JSON.parse(JSON.stringify(this.originalData));
      projectInstance.setWithoutScriptData(
        JSON.parse(JSON.stringify(this.originalData))
      );
      projectInstance.setWithoutScriptHistory([]);
      this.historyPosition = -1;
      this.containerKey += 1;
    }
  }

  undo() {
    if (
      projectInstance.withoutScriptHistory.length &&
      this.historyPosition >= 0
    ) {
      let history = projectInstance.withoutScriptHistory[
        this.historyPosition
      ];
      let action = Object.keys(history)[0];
      switch (action) {
        case "UPDATE_WORD":
          projectInstance.withoutScriptData[
            history[action].indexes.sentence
          ][history[action].indexes.word] = history[action].old;
          this.data[history[action].indexes.sentence][
            history[action].indexes.word
          ] = history[action].old;
          break;
        case "SPLIT_SENTENCE":
          projectInstance.withoutScriptData[
            history[action].sentence_1
          ] = projectInstance.withoutScriptData[
            history[action].sentence_1
          ].concat(
            projectInstance.withoutScriptData[history[action].sentence_2]
          );
          this.data[history[action].sentence_1] = this.data[
            history[action].sentence_1
          ].concat(this.data[history[action].sentence_2]);
          this.data.splice(history[action].sentence_2, 1);
          projectInstance.withoutScriptData.splice(
            history[action].sentence_2,
            1
          );
          break;
        case "DELETE_SENTENCE":
          this.data.splice(history[action].index, 0, history[action].value);
          projectInstance.withoutScriptData.splice(
            history[action].index,
            0,
            history[action].value
          );
          break;
        case "UPDATE_COLOR":
          this.data[history[action].index][0].color = history[action].old;
          projectInstance.withoutScriptData[
            history[action].index
          ][0].color = history[action].old;
          break;
        case "UPDATE_SENTENCE_POSITION":
          let sentence = this.data[history[action].new];
          this.data.splice(history[action].new, 1);
          this.data.splice(history[action].old, 0, sentence);
          projectInstance.setWithoutScriptData(
            JSON.parse(JSON.stringify(this.data))
          );
          break;
        case "DELETE_WORD":
          this.data.splice(history[action].index, 0, history[action].value);
          projectInstance.withoutScriptData.splice(
            history[action].index,
            0,
            history[action].value
          );
          break;
        default:
          break;
      }
      this.historyPosition -= 1;
    }
    this.containerKey += 1;
  }

  switchType() {
    this.$router.push("/process-script");
  }

  deleteWord(event: any) {
    if (this.element != null) {
      let element = this.element.getAttribute("data-object");
      let index: any = null;
      for (let i = 0; i < this.data.length; i++) {
        for (let j = 0; j < this.data[i].length; j++) {
          if (element == JSON.stringify(this.data[i][j])) {
            index = [i, j];
            break;
          }
        }
      }
      let action = {
        DELETE_WORD: {
          indexes: {
            word: index[1],
            sentence: index[0],
          },
          value: element,
        },
      };
      let arr = this.data[index[0]];
      let arr2 = projectInstance.withoutScriptData[index[0]];
      arr.splice(index[1], 1);
      arr2.splice(index[1], 1);
      this.data[index[0]] = arr;
      projectInstance.withoutScriptData[index[0]] = arr2;
      this.updateHistory(action);
      this.element = null;
      this.containerKey += 1;
    }
  }

  removeSentence(index: number) {
    let action = {
      DELETE_SENTENCE: {
        index: index,
        value: projectInstance.withoutScriptData[index],
      },
    };
    this.data.splice(index, 1);
    projectInstance.setWithoutScriptData(
      JSON.parse(JSON.stringify(this.data))
    );

    this.updateHistory(action);
    this.containerKey += 1;
  }

  splitSentence() {
    let data = JSON.parse(this.element.getAttribute("data-object"));
    let index: any = [];
    this.data.map((group: any, gIndex: number) => {
      group.map((word: any, wIndex: number) => {
        if (
          word.start_time == data.start_time &&
          word.text == data.text &&
          word.audios_uid == data.audios_uid
        ) {
          index = [gIndex, wIndex];
          return 0;
        }
      });
    });
    if (this.data[index[0]].length - 1 != index[1]) {
      let sentence1 = this.data[index[0]].slice(0, index[1]);
      let sentence2 = this.data[index[0]].slice(
        index[1],
        this.data[index[0]].length
      );
      this.data[index[0]] = sentence1;
      this.data.splice(index[0] + 1, 0, sentence2);
      projectInstance.withoutScriptData[index[0]] = JSON.parse(
        JSON.stringify(sentence1)
      );
      projectInstance.withoutScriptData.splice(
        index[0] + 1,
        0,
        JSON.parse(JSON.stringify(sentence2))
      );
      let action = {
        SPLIT_SENTENCE: {
          sentence_1: index[0],
          sentence_2: index[0] + 1,
        },
      };
      this.updateHistory(action);
      this.containerKey += 1;
    }
  }
  dragEnd(event: any) {
    if (this.afterElement != undefined) {
      let draggable: HTMLElement | null = document.querySelector(".dragging");
      if (draggable) {
        let words: any;
        let group: any = [];
        let data: any = [];
        let data2: any = [];
        let action = {
          UPDATE_SENTENCE_POSITION: {
            old: Number(draggable?.dataset.index),
            new: 0,
          },
        };
        if (this.afterElement == null) {
          this.target.appendChild(draggable);
          action.UPDATE_SENTENCE_POSITION.new =
            Number(this.target?.dataset.index) + 1;
        } else if (this.afterElement?.nextSibling == null) {
          document.querySelector("#dropzone")?.appendChild(draggable);
          action.UPDATE_SENTENCE_POSITION.new = this.data.length - 1;
        } else if (this.afterElement?.classList.contains("drop-top")) {
          this.afterElement?.insertAdjacentElement("beforebegin", draggable);
          action.UPDATE_SENTENCE_POSITION.new = Number(this.afterElement.dataset.index);
        } else {
          this.afterElement?.insertAdjacentElement("afterend", draggable);
          action.UPDATE_SENTENCE_POSITION.new =
            Number(this.afterElement.dataset.index);
        }
        let containers = document.querySelectorAll(".draggable");
        draggable?.classList.remove("dragging");
        containers.forEach((container: any) => {
          words = container?.querySelectorAll(".word-container");
          words.forEach((word: any) => {
            group.push(JSON.parse(word.getAttribute("data-object")));
          });
          data.push(group);
          data2.push(group);
          group = [];
        });
        this.afterElement.classList.remove("drop-top");
        this.afterElement.classList.remove("drop-bottom");
        this.data = data2;
        this.containerKey += 1;
        projectInstance.setWithoutScriptData(data);
        this.updateHistory(action);
      }
    }
  }

  dragStart(event: any) {
    event.target.classList.add("dragging");
  }

  dragOver(event: any) {
    event.preventDefault();
    document.querySelectorAll(".draggable").forEach((el: any) => {
      el.classList.remove("drop-top");
      el.classList.remove("drop-bottom");
    });
    if (event.target.id == "dropzone") {
      const [afterElement, border] = this.getDragAfterElement(
        event.target,
        event.clientY
      );
      this.afterElement = afterElement;
      this.target = event.target;
      if (afterElement != undefined && afterElement != null) {
        afterElement.classList.add(`drop-${border}`);
      }
    } else if (event.target.classList.contains(".draggable")) {
      const element = event.target;
      let box = element.getBoundingClientRect();
      let offset = event.clientY - box.top - box.height / 2;
      if (offset <= 0) {
        element.classList.add("drop-top");
      } else {
        element.classList.add("drop-bottom");
      }
      this.afterElement = element;
    } else {
      const element = event.target.closest(".draggable");
      let box = element.getBoundingClientRect();
      let offset = event.clientY - box.top - box.height / 2;
      if (offset <= 0) {
        element.classList.add("drop-top");
      } else {
        element.classList.add("drop-bottom");
      }
      this.afterElement = element;
    }
  }

  getDragAfterElement(container: any, y: any) {
    const elements = container.querySelectorAll(".draggable:not(.dragging)");
    let box: any = null;
    let closest: any = [];
    let offset = 0;
    elements.forEach((element: any) => {
      box = element.getBoundingClientRect();
      offset = y - box.top - box.height / 2;
      closest.push({
        element: element,
        border: offset <= 0 ? "top" : "bottom",
        offset: Math.abs(offset),
      });
    });
    let element: any = closest[0];
    closest.map((el: any) => {
      if (el.offset <= element.offset) {
        element = el;
      }
    });
    return [element.element, element.border];
  }

  openFAQ() {
    let urlFr =
      "https://www.notion.so/pomelofactory/1ec0de19990a49bb8e87e9f042a10447?v=190f48c245ca470186b507f755b93caf";
    let urlEn =
      "https://www.notion.so/pomelofactory/ff593a36f11e4b5dae2d2435d136abd5?v=262cab39381148ddb32f2f948d1b1438";
    if (this.$i18n.locale == "fr-FR") {
      open(urlFr, '_blank');
    } else {
      open(urlEn, '_blank');
    }
  }

  async edit(wIndex: number, index: number) {
    await this.saveAll();
    let selected = document.querySelector('.highlighted');
    selected?.classList.remove('highlighted');
    let el = document.querySelector(`#badge_${index}_${wIndex}`)?.parentElement;
    let word = this.data[index][wIndex];
    let container = document.createElement("div");
    container.id = "mount-node";
    el?.appendChild(container);
    let component = Vue.extend(EditWord);
    let editor = new component({
      propsData: {
        word,
        wIndex,
        index,
        saveInput: this.saveInput,
        save: this.save,
      },
    }).$mount("#mount-node");
    document
      .querySelector(`#badge_${index}_${wIndex}`)
      ?.classList.add("d-none");
    let input: HTMLInputElement | null = document.querySelector(
      ".input-icon input"
    );
    input?.focus();
  }

  async saveAll() {
    if (document.querySelector(".input-icon")) {
      let el: HTMLInputElement | null = document.querySelector(
        ".input-icon input"
      );
      let container = document.querySelector(".input-icon");
      let gIndex: any = el?.dataset.groupid;
      let wordIndex: any = el?.dataset.wordid;
      gIndex = gIndex == undefined ? 0 : parseInt(gIndex);
      wordIndex = wordIndex == undefined ? 0 : parseInt(wordIndex);
      let action = {
        UPDATE_WORD: {
          indexes: {
            word: wordIndex,
            sentence: gIndex,
          },
          old: this.data[gIndex][wordIndex].text,
          new: el?.value,
        },
      };
      this.data[gIndex][wordIndex].text = el?.value;
      projectInstance.withoutScriptData[gIndex][wordIndex].text =
        el?.value;
      container?.classList.remove("d-inline");
      container?.classList.add("d-none");
      document
        .querySelector(`#badge_${gIndex}_${wordIndex}`)
        ?.classList.remove("d-none");
      this.updateHistory(action);
      this.containerKey += 1;
    }
  }

  saveInput(wIndex: number, index: number) {
    let input: HTMLInputElement | null = document.querySelector(
      ".input-icon input"
    );
    let action = {
      UPDATE_WORD: {
        indexes: {
          word: wIndex,
          sentence: index,
        },
        old: this.data[index][wIndex].text,
        new: input?.value,
      },
    };
    this.data[index][wIndex].text = input?.value;
    projectInstance.withoutScriptData[index][wIndex].text = input?.value;
    let container = document.querySelector(`#input_${index}_${wIndex}`);
    container?.classList.remove("d-inline");
    container?.classList.add("d-none");
    document
      .querySelector(`#badge_${index}_${wIndex}`)
      ?.classList.remove("d-none");
    this.updateHistory(action);
    this.containerKey += 1;
  }

  save(wIndex: number, index: number, event: any) {
    let action = {
      UPDATE_WORD: {
        indexes: {
          word: wIndex,
          sentence: index,
        },
        old: this.data[index][wIndex].text,
        new: event.target.value,
      },
    };
    this.data[index][wIndex].text = event.target.value;
    projectInstance.withoutScriptData[index][wIndex].text =
      event.target.value;
    let input = document.querySelector(`#input_${index}_${wIndex}`);
    input?.classList.remove("d-inline");
    input?.classList.add("d-none");
    document
      .querySelector(`#badge_${index}_${wIndex}`)
      ?.classList.remove("d-none");
    this.updateHistory(action);
    this.containerKey += 1;
  }

  sendFeedback(rating: number) {
    if (rating < 5) {
      this.$router.push(`/feedback/${rating}`);
    }
    let feed: any = {
        rating: rating,
        page_uid: 4,
      };
    feedbackInstance
      .putFeed(feed)
      .then((res: any) => {
        if (res) {
          this.alertSuccess = true;
          this.error = false;
          // this.$mixpanel.track('Feedback Success', {
          //   distinct_id: this.AuthInstance.user?.username,
          // });
        } else {
          this.alertSuccess = false;
          this.error = true;
        }
      });
  }

  rows() {
    return Math.ceil(this.script.length / 76);
  }

  openSearch() {
    document.getElementById("searchDiv")?.classList.add("open");
  }

  closeSearch() {
    document.getElementById("searchDiv")?.classList.remove("open");
  }

  async getOrganizationInstructions() {
    let token = window.localStorage.getItem("Token") ?? "";
    let url = `${conf.API_LOCATION}/generate_instruction_scripted`;

    const response = await fetch(url, {
      method: "POST",
      mode: "cors",
      body: JSON.stringify({ data: projectInstance.withoutScriptData }),
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer ".concat(token),
      },
      redirect: "follow",
    });
    return await response.json();
  }

  async sendScripts() {
    const response = await projectInstance.sendInstructionScripted({
      data: projectInstance.withoutScriptData,
      time_between_words: parseFloat(projectInstance.withoutScriptTimeBetweenWords),
      paddingBefore: parseFloat(projectInstance.withoutScriptPaddingBefore),
      paddingAfter: parseFloat(projectInstance.withoutScriptPaddingAfter),
    });
    this.scriptProcessed = true;
    // this.$mixpanel.track('Edit Script Success', {
    //   distinct_id: this.AuthInstance.user?.username,
    // });
    return response?.data;
  }

  async addToResults(first: boolean, index: Array<number>, numWords: number) {
    for (let i = 0; i < numWords; i++) {
      let wordId = index[1] + i;
      let id = "badge_" + index[0] + "_" + wordId;
      this.searchIds.push(id);
      document.getElementById(id)?.classList.add("result");
      if (first) {
        let chosenIndex = this.searchIds.findIndex((element) => element == id);
        this.chosenWord = id;
        console.log("first" + this.chosenWord);
        document.getElementById(this.chosenWord)?.classList.add("chosen");
        document.getElementById(this.chosenWord)?.scrollIntoView({
          behavior: "auto",
          block: "center",
          inline: "center",
        });
      }
    }
  }

  async findText(
    first: boolean,
    block: string,
    blockIndex: number,
    text: string
  ) {
    let words = block.toLowerCase().split(" ");
    let numWords = 1;
    if (text.indexOf(" ") > 0) {
      let arr = text.split(" ");
      text = arr[0];
      if (arr[1].length != 0) {
        numWords = arr.length;
      }
    }
    for (let i = 0; i < words.length; i++) {
      if (words[i].indexOf(text.toLowerCase()) >= 0) {
        this.addToResults(first, [blockIndex, i], numWords);
        first = false;
      }
    }
  }

  async findBlock(text: string) {
    this.scriptBlocks = [];
    let text_promise = projectInstance.latinise(text);
    text = await text_promise;
    projectInstance.withoutScriptData.map((group: Array<string>) => {
      let blocks: string = "";
      group.map((word: any) => {
        blocks += word.text.toLowerCase() + " ";
      });
      this.scriptBlocks.push(blocks);
      blocks = "";
    });
    if (this.searchIds.length > 0) {
      for (let i = 0; i <= this.searchIds.length; i++) {
        document.getElementById(this.searchIds[i])?.classList.remove("result");
        document.getElementById(this.searchIds[i])?.classList.remove("chosen");
      }
    }
    if (text.length == 0) {
      return;
    }
    this.searchIds = [];

    let first = true;
    let rest = {};
    for (let i = 0; i < this.scriptBlocks.length; i++) {
      let text_promise = projectInstance.latinise(this.scriptBlocks[i]);
      this.scriptBlocks[i] = await text_promise;
      if (this.scriptBlocks[i].indexOf(text) >= 0) {
        this.findText(first, this.scriptBlocks[i], i, text);
        first = false;
      }
    }
  }

  previousWord() {
    let chosenIndex = this.searchIds.findIndex((id) => id == this.chosenWord);
    if (chosenIndex == 0) {
      this.chosenWord = this.searchIds[this.searchIds.length - 1];
    } else {
      this.chosenWord = this.searchIds[chosenIndex - 1];
    }
    this.goToWord(chosenIndex);
  }

  nextWord() {
    let chosenIndex = this.searchIds.findIndex((id) => id == this.chosenWord);
    if (chosenIndex == this.searchIds.length - 1) {
      this.chosenWord = this.searchIds[0];
    } else {
      this.chosenWord = this.searchIds[chosenIndex + 1];
    }
    this.goToWord(chosenIndex);
  }

  goToWord(chosenIndex: number) {
    document
      .getElementById(this.searchIds[chosenIndex])
      ?.classList.remove("chosen");
    document.getElementById(this.chosenWord)?.classList.add("chosen");
    document.getElementById(this.chosenWord)?.scrollIntoView({
      behavior: "smooth",
      block: "center",
      inline: "center",
    });
  }

  // Computed

  set searchComputed(value: any) {
    this.keyword = value;
    this.findBlock(value.trim().toLowerCase());
  }

  get searchComputed() {
    return this.keyword;
  }
}
