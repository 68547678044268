


























import Vue from "vue";
import { Component } from "vue-property-decorator";
import logoutButton from "@/views/components/LogOutComponent.vue";

import { getModule } from "vuex-module-decorators";
import AppModule from "@/store/modules/app";
import AuthInstance from "@/store/modules/auth";
import Header from "./components/Header.vue";
import FeedbackModule from "@/store/modules/feedback";

@Component({
  components: {
    logoutButton,
    Header,
  },
})
export default class BugReport extends Vue {
  success: boolean = false;
  error: boolean = false;
  empty: boolean = false;
  message: string = "";
  feedInstance = FeedbackModule;
  AuthInstance = AuthInstance;
  app = AppModule;

 
  created() {
    this.app.setCurrentPage(this.$router.currentRoute.path);
  }

  
  async send() {
    this.empty = false;
    if (this.message) {
      let data: any = {
        message: this.message,
        subject: "Other",
        page_uid: 1,
		rating: 6
      };
      if (this.$route.params.rating) {
        data.rating = parseInt(this.$route.params.rating);
      }
      await this.feedInstance
        .putFeed(data)
        .then((res: any) => {
          this.success = true;
          this.error = false;
          // this.$mixpanel.track("Feedback Success", {
          //   distinct_id: this.AuthInstance.user.username,
          // });
        })
        .catch(() => {
          this.error = true;
          this.success = false;
        });
    } else {
      this.empty = true;
    }
  }
}
