















































































import logoutButton from "@/views/components/LogOutComponent.vue";
import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import AuthsModule from "@/store/modules/auth";

const AuthInstance = AuthsModule;
@Component({
  components: {
    logoutButton,
  },
})
export default class Header extends Vue {
  @Prop({ default: false })
  transcribing: boolean = false;
  @Prop({ type: Function, default: () => {} })
  cancel!: any;


  toProjects() {
    this.$router.push("/");
  }

  toProfile() {
    
  }

  async logout() {
    let confirmation = confirm(`${this.$t("message.auth.disconnect")}`);
    if (confirmation) {
      AuthInstance.logout();
      this.$router.push("/login").catch((e) => console.log(e));
    }
  }
}
