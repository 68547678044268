<template>
  <div id="app" class="pt-4">
    <router-view/>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  created(){
      axios.interceptors.response.use(
        (response) => {
          console.log("axios res");
          console.log(response);
          return response;
        },
        (error) => {
          console.log("axios error");
          console.log(error.response);
          switch (error.response.status) {
            case 401:
              localStorage.removeItem("Token");
              localStorage.removeItem("Token-refresh");
              localStorage.removeItem("expiration_date");
              evalScript('alert("You have logged into another machine")');
              this.$router.push('/login');
              console.log("forced logout");
              break;
            case 500:
              // Handle 500 here
              // redirect
              break;
            // and so on..
          }
      
          if (error.response.status == 400) {
          }
          return Promise.reject(error);
        }
      );
    },
}
</script>
<style>
  #app {
    background-color: #222222;
    color: white;
  }
</style>