































import AuthsModule from "@/store/modules/auth";
import Vue from "vue";
import { Component } from "vue-property-decorator";
import feedback from "@/views/components/FeedBackComponent.vue";

import ProjectsModule from "@/store/modules/project";
import AppModule from "@/store/modules/app";

const AuthInstance = AuthsModule;
const ProjectInstance = ProjectsModule;
const app = AppModule;

@Component({
  components: {
    feedback,
  },
})
export default class LogOutComponent extends Vue {

  created() {
    console.log(this.$router.currentRoute.name)
  }
  //////////////////////////////////////////////////////////////////////////
  /// METHODS
  //////////////////////////////////////////////////////////////////////////
  async logout() {
    // let confirm = confirm(`${this.$t("message.auth.disconnect")}`);
    // if (confirm) {
    //   AuthInstance.logout();
    //   this.$router.push("/login").catch((e) => console.log(e));
    // }
  }

  async goback() {
    let confirm = true;
    app.setCurrentPage("");
    if (this.$router.currentRoute.name == "recommendation") {
      window.localStorage.setItem("transcribed", "0");
    } else if (
      this.$router.currentRoute.name == "process-script" ||
      this.$router.currentRoute.name == "without-script"
    ) {
      
      // confirm(`${this.$t("message.interview_editor.go_home_warning")}`, false, 'warning');
      if (confirm) {
        ProjectInstance.setTranscribe(false);
        ProjectInstance.setScripts([]);
        ProjectInstance.setHistory([]);
        ProjectInstance.setWithoutScriptData([]);
        ProjectInstance.setWithoutScriptHistory([]);
        // Sentry.configureScope((scope) => scope.setUser(null));
        window.localStorage.setItem("transcribed", "0");
        this.$router.push("/");
        return 0;
      }
    }
    if (confirm) {
      this.$router.back();
    }
  }
}
