


































































































































































import Vue from "vue";
import { Component, Prop, Watch } from "vue-property-decorator";
import AppModule from "@/store/modules/app";
import * as _ from 'lodash';
import ProjectsModule from "@/store/modules/project";

@Component({})
export default class Navbar extends Vue {
  @Prop({ type: Function, default: () => {} })
  findBlock: any;
  @Prop({ type: Function, default: () => {} })
  reset: any;
  @Prop({ type: Function, default: () => {} })
  undo: any;
  @Prop({ type: Function, default: () => {} })
  download: any;
  @Prop({ type: Function, default: () => {} })
  nextWord: any;
  @Prop({ type: Function, default: () => {} })
  previousWord: any;
  @Prop({ type: Function, default: () => {} })
  showModal: any;
  @Prop({ type: Function, default: () => {} })
  openFAQ: any;
  @Prop({ type: Function, default: () => {} })
  search: any;
  @Prop({ type: Function, default: () => {} })
  quit: any;
  @Prop({ type: Function, default: () => {} })
  showSaveModal: any;

  showMenu: boolean = false;
  editScript: boolean = false;
  keyword: string = "";
  ProjectInstance = ProjectsModule;
  app = AppModule;
  open: boolean = false;

  @Watch("keyword")
  onPropertyChanged = _.debounce((value) => {
    this.search(value);
  }, 600);

  timeBetweenWords: string = "4"; // in seconds
  paddingBefore: string = "0.0"; // in seconds
  paddingAfter: string = "0.0"; // in seconds
  updateExistingState: boolean = false;
  showMoreOptions: boolean = false;

  set searchComputed(value: any) {
    this.keyword = value.trim().toLowerCase();
    this.findBlock(value.trim().toLowerCase());
  }

  get searchComputed() {
    return this.keyword;
  }

  created() {
    if (this.$router.currentRoute.name == "without-script") {
      this.editScript = true;
    }
  }

  toggleMoreOptions (){
    this.ProjectInstance.setWithoutScriptshowMoreOptions(!this.ProjectInstance.withoutScriptshowMoreOptions);
  }

  async goback() {
    let confirm = true;
    this.app.setCurrentPage("");

    // if (this.editScript) {
    //   confirm = await evalScript(
    //     `confirm('${this.$t(
    //       "message.interview_editor.go_home_warning"
    //     )}', false, 'warning')`
    //   );
    // }
    if (confirm) {
      this.ProjectInstance.clearAll();
      // Sentry.configureScope((scope) => scope.setUser(null));
      window.localStorage.setItem("transcribed", "0");
      this.$router.push("/");
      return 0;
    }
  }
}
