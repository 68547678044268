const messages = {
  app: {
    home: "Home",
    projects: "Projects",
    logout: "Log out",
    help: "Help",
  },
  auth: {
    login: "Log In",
    username: "Username",
    title: "Log in and let the magic happen",
    password: "password",
    invalid: "Invalid username or password",
    forgot: "I forgot my password",
    first_time: "First time using Derush?",
    disconnect: "Are you sure you want to disconnect?",
    empty_inputs: "Username and password should not be empty!"
  },
  interview_editor: {
    title: "Interview Editor",
    bin: "Select bin",
    hints: "Add hints",
    quality: "High quality",
    multicam: "Multi-Source Sequence",
    transcribe: "Transcribe",
    organize: "Organize Footage",
    uploading: "Working on uploading... Chill a minute",
    transcribing: "Working on transcribing... We have it under control",
    analyzing: "Working on analyzing words... What elegance",
    go_home_warning: "Are you sure you want to go back to Transcription? You will lose this one.",
    empty_bin: "The bin is empty or contains invalid formats.",
    cancel_transcription: "Cancel",
    cancel_warning: "Transcription is still ongoing. Are you sure you want to stop?",
    tooltip_bin: "Bin is the Premiere Pro folder that contains the media files you want to transcribe.",
    tooltip_hints: "Hints are comma separated keywords thare are mentioned and important in your interview. Think of proper nous and technical terms.",
    tooltip_quality: "High quality media will take longer to upload, but will increase transcription accuracy",
    tooltip_multicam: "First synchronize your video/audio sources in a Premiere Pro sequence, then select the bin containing this sequence.",
    tooltip_save_project: "Save project",
    error_quota: "Your quota is too low to transcribe this bin.",
    undo: "Undo",
    reset: "Reset",
    delete: "Delete",
    reset_warning: "Are you sure you want to reset your work?",
    download: "Download transcript",
    generated: "Transcript generated! You can find it in the Media panel",
    quota_reached: "Your maximum transcription time has been reached",
    error_transcription: "Error in Transcription, please retry",
    error_ffmpeg: "Error in ffmpeg",
    error_media: "Error Media is offline",
    error_multicam_bin: "The bin must contain only one valid sequence.",
    ratio: "the final sequence includes {0}% of the duration of your rushes",
    options: {
      show_sequence: "Reveal in Sequence",
      split_sentence: "New Paragraph",
    },
    info: {
      text_1: "Right click on a word to reveal features :",
      text_2: "When you're satisfied with your transcript, click on\
      <br />\
      <span class='organize-btn mr-2'>GO</span> to create a\
      new assembled sequence.",
      text_3: "Download your timecoded script by clicking on\
      <span class='download-btn mr-2'>\
        <Icon name='download' size='12px' />\
      </span>"
    }
  },
  feedback: {
    title: "Feedback",
    intro: "What do you want to give feedback on?",
    comment: "Tell us everything",
    submit: "Submit",
    thanks: "Thanks for your feedback! We'll make sure <br/> you're super satisfied with the product! 🌈",
    options: "[\"Login\", \"Interview Editor\", \"Silence Removal\", \"Parameters\", \"Loading\", \"Data privacy\", \"Other\"]",
    error: "Error occurred while sending your feedback. <br/> Try again",
    empty: "Subject and message should not be empty",
    rating_missing: "Please click an emoji to set your rating"
  },
  recommendation: {
    title: "Recommendation",
    referral: "How likely are you to recommend Derush <br/> to your friends and colleagues",
    rating: "How do you like it so far?",
    submit: "Submit",
    thanks: "Thanks for your feedback! We'll make sure <br/> you're super satisfied with the product! 🌈",
    error: "Error occurred while sending your recommendation. <br/> Try again",
    error_empty: "Select a rating and message",
    message_label: "Tell us what you like and don't like so we can get better",
  },
  account: {
    title: "Account",
    username: "Your username",
    password: "Your password",
    email: "Your email",
    subscription: "Your subscription",
    save: "Save",
    updated: "Changes have been saved! ✅",
    quota: "You have used {0} of your {1} of transcription available.",
    upgrade: "Upgrade"
  },
  password_recovery: {
    title: "Password Recovery",
    email: "Your email",
    send: "Send",
    error_email: "The provided email is not associated with an account",
    code: "Verification code",
    new_password: "Your new password",
    update: "Update",
    incorrect_token: "Token incorrect",
    success: "Your password updated successfully"
  },
  transcript: {
    generated: "Transcript generated! You can find it in the Media panel",
    error_import: "Error in import file",
  },
  navbar: {
    moreOptions: "More options",
    afterPadding: "Padding after (s)",
    beforePadding: "Padding before (s)",
    splitGap: "Split sentence with a gap superior than",
    updateExisting: "Update existing sequence",
  },
  projects: {
    show_all: 'Show all projects',
  }

};

export default messages;