import store from '@/store';
import { VuexModule, Module, Action, Mutation,getModule } from 'vuex-module-decorators';
import {Project ,Quota, QuotaSilence} from '../models';
import { project } from './api';
import { Pomelo } from '../declarations';
import { Dictionary } from 'vue-router/types/router';

@Module({
  namespaced: true,
  dynamic: true,
  name: 'project',
  store,
  preserveState: localStorage.getItem('vuex') !== null,
})
class ProjectsModule extends VuexModule {
  project: Project = {
    project_uid: "",
  }

  scripts: any = []
  history: any = []
  withoutScriptHistory: any = []
  withoutScriptData: any = []
  ranges: any = []
  sequences: any = []

  // multicam
  isMulticam: boolean = false;
  multicamData = {};

  // navbar params
  withoutScriptPaddingBefore = ""
  withoutScriptPaddingAfter = ""
  withoutScriptTimeBetweenWords = ""
  withoutScriptUpdateExistingState = false
  withoutScriptshowMoreOptions = false

  transcribed = false;
  activateRemoval = false;

  quota: Quota = {
    current_quota : 0,
    isQuotaReached : false,
    quota_limit: 0,
  }
  
  quotaSilence: QuotaSilence = {
    silence_removal_data : 0,
  }

  transcriptDownloadCount = 0;
  selected_bin_name = '';
  selected_bin: any = [];
  project_name = '';

  french_accents : Dictionary<string> = {
    'ç' : 'c',
    'é' : 'e',
    'â' : 'a',
    'ê' : 'e',
    'î' : 'i',
    'ô' : 'o',
    'û' : 'u',
    'à' : 'a',
    'è' : 'e',
    'ù' : 'u',
    'ë' : 'e',
    'ï' : 'i',
    'ü' : 'u'
    }

  get uid(): string {
      return this.project.project_uid;
  }

  get transcribe() {
    return this.transcribed;
  }

  get removal() {
    return this.activateRemoval;
  }

  @Mutation
  setMulticam(value: boolean) {
    this.isMulticam = value;
  }

  @Mutation
  setMulticamData(value: any) {
    this.multicamData = value;
  }

  @Mutation
  setProjectName(value: string) {
    this.project_name = value;
  }

  @Mutation
  setSelectedBinName(value: string) {
    this.selected_bin_name = value;
  }

  @Mutation
  setSelectedBin(value: any) {
    this.selected_bin = value;
  }

  @Mutation
  setWithoutScriptData(value: any) {
    this.withoutScriptData = value;
  }

  @Mutation
  setWithoutScriptHistory(value: any) {
    this.withoutScriptHistory = value;
  }  
  
  @Mutation
  setScripts(value: any) {
    this.scripts = value;
  }

  @Mutation
  setHistory(value: any) {
    this.history = value;
  }

  @Mutation
  setRemoval(value: boolean) {
    this.activateRemoval = value;
  }

  @Mutation
  setRanges(value: any) {
    this.ranges = value;
  }

  @Mutation
  setSequences(value: any) {
    this.sequences = value;
  }

  @Mutation
  setTranscribe(value: boolean) {
    this.transcribed = value;
  }

  @Mutation
  setQuota(quota: Quota){
    this.quota = quota;
  }

  @Mutation
  setQuotaSilence(quota: QuotaSilence){
    this.quotaSilence = quota;
  }

  @Mutation
  setProject(project: Project){
    this.project = project;
  }

  @Mutation
  setTranscriptDownloadCount(value: number){
    this.transcriptDownloadCount = value;
  }


  @Mutation
  setWithoutScriptPaddingBefore(value: any) {
    this.withoutScriptPaddingBefore = value;
  }

  @Mutation
  setWithoutScriptPaddingAfter(value: any) {
    this.withoutScriptPaddingAfter = value;
  }

  @Mutation
  setWithoutScriptTimeBetweenWords(value: any) {
    this.withoutScriptTimeBetweenWords = value;
  }

  @Mutation
  setWithoutScriptUpdateExistingState(value: any) {
    this.withoutScriptUpdateExistingState = value;
  }

  @Mutation
  setWithoutScriptshowMoreOptions(value: any) {
    this.withoutScriptshowMoreOptions = value;
  }
  /**
   * initialisation du projet de travail
   */
  @Action({ commit:'setProject' })
  async initialise() {
    // @ts-ignore
    let token = window.localStorage.getItem("Token") ?? "";
    const uid = await project.initProject(token);
    const response = {
        project_uid: uid
    };
    // console.log(response);
    return response;
  }
  /**
   * Envoie des rush au back end
   * @param rush 
   */
  @Action({})
  async sendRush(rush: Pomelo.Rush){
    let remote_rush : Pomelo.RemoteRush  = new Pomelo.RemoteRush();
    remote_rush.media_index = rush.media_index;
    let token = window.localStorage.getItem("Token") ?? "";
    let uid = await project.sendApiObject(
      // @ts-ignore
      store.state.project.project.project_uid, 
      remote_rush, 
      // @ts-ignore
      token)
    .then((res)=>{
      return res;
    });
    return uid;
  }
  /**
   * Envoie du script au backend
   * @param file 
   */
  @Action({rawError: true})
  async sendScript(file:string){
    let token = window.localStorage.getItem("Token") ?? "";
    return project.sendScriptApi(
      file,
      // @ts-ignore
      store.state.project.project.project_uid, 
      // @ts-ignore
      token);
  }


  @Action({commit: 'setQuota',rawError: true})
  async getQuota(){
    // @ts-ignore
    let token = window.localStorage.getItem("Token") ?? "";
    const result = await project.getQuotaApi(token);
    let quota:Quota = result.data;
    return quota;
  }

  @Action({commit: 'setQuota',rawError: true})
  async addQuota(durationOfTrack:number){
    // @ts-ignore
    let token = window.localStorage.getItem("Token") ?? "";
    const result = await project.addQuota(durationOfTrack,token);
    let quota:Quota = this.quota;
    
    if(result?.success){
      // @ts-ignore
      const result = await project.getQuotaApi(token);
      if(result.data){
        let quotaR:Quota = result.data;
        return quotaR;
      }
      
    }else{
       let quotaR:Quota = quota;
      return quotaR;
    }
    
  }

  @Action({commit: 'setQuotaSilence',rawError: true})
  async getQuotaSilence(){
    // @ts-ignore
    let token = window.localStorage.getItem("Token") ?? "";
    const result = await project.getQuotaSilenceApi(token);
    let quotaSilence:QuotaSilence = result.data;
    return quotaSilence;
  }

  @Action({commit: 'setQuotaSilence',rawError: true})
  async addQuotaSilence(durationOfTrack:number){
    // @ts-ignore
    let token = window.localStorage.getItem("Token") ?? "";
    const result = await project.addQuotaSilence(durationOfTrack,token);
    
    if(result?.success){
      // @ts-ignore
      const result = await project.getQuotaSilenceApi(token);
      if(result.data){
        let quotaR:QuotaSilence = result.data;
        return quotaR;
      }
      
    }else{
      let quotaR:QuotaSilence = this.quotaSilence;
      return quotaR;
    }
    
  }

  @Action({rawError: true})
  async getScripts() {
    let token = window.localStorage.getItem('Token') ?? "";
    const result = await project.getScript(token, this.uid);
    return result;
  }

  @Action({rawError: true})
  async getFakeScripts(index: number) {
    let token = window.localStorage.getItem('Token') ?? "";
    const result = await project.getFakeScript(token, this.uid, index);
    return result;
  }

  @Action({rawError: true})
  async sendScripts(scripts: string) {
    let token = window.localStorage.getItem('Token') ?? "";
    const result = await project.sendScriptApi(scripts, this.uid, token);
    return result;
  }

  @Action({rawError: true})
  async generateScripts() {
    let token = window.localStorage.getItem('Token') ?? "";
    const result = await project.generateScript(token, this.uid);
    return result;
  }

  @Action({rawError: true})
  async sendInstructionScripted(data: any) {
    let token = window.localStorage.getItem('Token') ?? '';
    const result = await project.sendInstructions(token, data);
    return result;
  }

  @Action({rawError: true})
  clearAll() {
    this.setScripts([]);
    this.setHistory([]);
    this.setWithoutScriptHistory([]);
    this.setWithoutScriptData([]);
    this.project.project_uid = "";
    this.setSelectedBinName("");
    this.setTranscriptDownloadCount(0);

    this.setWithoutScriptPaddingAfter("");
    this.setWithoutScriptPaddingBefore("");
    this.setWithoutScriptTimeBetweenWords("");
    this.setWithoutScriptUpdateExistingState(false);
    this.setWithoutScriptshowMoreOptions(false);

    this.setProjectName("");
    window.localStorage.setItem("uid_dict", "{}");
  }

  @Action({rawError: true})
  latinise(value: string): any {
    let accent_map = this.french_accents;
    return value.replace(/[^A-Za-z0-9]/g, function(x: any) { return accent_map[x] || x; });
  }

}

export default getModule(ProjectsModule)